import React from 'react';
import './Skill.css';

const Skill = ({skill,progress,width}) => {
  return (
    <div className='box'>
        <h3>{skill} <span>{progress}</span></h3>
        <div className='progress-bar'> <span style={{width:width}}></span> </div>
    </div>
  )
}

export default Skill;