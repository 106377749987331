const education = [

    {
        id: 1, 
        duration: '2015 - 2016',
        title: 'science',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat est nemo, omnis iste distinctio consequuntur delectus facere suscipit facilis perspiciatis error.',
    },
    {
        id: 2,
        duration: '2016 - 2017',
        title: 'software engineering',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat est nemo, omnis iste distinctio consequuntur delectus facere suscipit facilis perspiciatis error.',
    },
    {
        id: 3,
        duration: '2017 - 2018',
        title: 'computer science',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat est nemo, omnis iste distinctio consequuntur delectus facere suscipit facilis perspiciatis error.',
    },
 
];

export default education;