import { images } from '../constants';

const blogs = [

    {
        id: 1,
        Image: images.blog1,
        category: 'development',
        title: 'How to make your website responsive',
        link: '#',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date : '1st',
        month: 'jan',
        year: '2020'
    },
    {
        id: 2,
        Image: images.blog2,
        category: 'design',
        title: 'Learn Web Development As A Beginner',
        link: '#',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date : '1st',
        month: 'jan',
        year: '2020'
    },
    {
        id: 3,
        Image: images.blog3,
        category: 'marketing',
        title: 'Complete Roadmap For Web Development',
        link: '#',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        date : '1st',
        month: 'jan',
        year: '2020'
    },
 
];

export default blogs;