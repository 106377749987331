import React from 'react';
import './Skills.css';

import { Heading, Skill } from '../../components';

const Skills = () => {
  return (
    <div className='skill-container'> 

      <Heading title={'my Skills'} span={'programming skills'} />
 
      <div class="skills"> 

          <div className='skill-item item1'>
              <Skill skill={'HTML5'} progress={'95%'} width={'95%'} />
              <Skill skill={'CSS3'} progress={'90%'} width={'90%'} />
              <Skill skill={'javascript'} progress={'85%'} width={'85%'} />
          </div>

          <div class="skill-item item2">
              <Skill skill={'JQuery'} progress={'75%'} width={'75%'} />
              <Skill skill={'Bootstrap'} progress={'65%'} width={'65%'} />
              <Skill skill={'PHP'} progress={'60%'} width={'60%'} />
          </div>

      </div>

    </div>
  )
}

export default Skills