import { images } from '../constants';

export const webPortfolios = [
    {
        id: 1,
        Image: images.webPortfolio1,
        title: 'project title',
        category: 'web development',
        link: '#'
    },
    {
        id: 2,
        Image: images.webPortfolio2,
        title: 'project title',
        category: 'web development',
        link: '#'
    },
    {
        id: 3,
        Image: images.webPortfolio3,
        title: 'project title',
        category: 'web development',
        link: '#'
    },
    {
        id: 4,
        Image: images.webPortfolio4,
        title: 'project title',
        category: 'web development',
        link: '#'
    },
    {
        id: 5,
        Image: images.webPortfolio5,
        title: 'project title',
        category: 'web development',
        link: '#'
    },
    {
        id: 6,
        Image: images.webPortfolio6,
        title: 'project title',
        category: 'web development',
        link: '#'
    },
];



export const designPortfolios = [
    {
        id: 1,
        Image: images.designPortfolio1,
        title: 'project title',
        category: 'graphic design',
        link: '#'
    },
    {
        id: 2,
        Image: images.designPortfolio2,
        title: 'project title',
        category: 'graphic design',
        link: '#'
    },
    {
        id: 3,
        Image: images.designPortfolio3,
        title: 'project title',
        category: 'graphic design',
        link: '#'
    },
    {
        id: 4,
        Image: images.designPortfolio4,
        title: 'project title',
        category: 'graphic design',
        link: '#'
    },
    {
        id: 5,
        Image: images.designPortfolio5,
        title: 'project title',
        category: 'graphic design',
        link: '#'
    },
    {
        id: 6,
        Image: images.designPortfolio6,
        title: 'project title',
        category: 'graphic design',
        link: '#'
    },
];



export const photographyPortfolios = [
    {
        id: 1,
        Image: images.photoPortfolio1,
        title: 'project title',
        category: 'photography',
        link: '#'
    },
    {
        id: 2,
        Image: images.photoPortfolio2,
        title: 'project title',
        category: 'photography',
        link: '#'
    },
    {
        id: 3,
        Image: images.photoPortfolio3,
        title: 'project title',
        category: 'photography',
        link: '#'
    },
    {
        id: 4,
        Image: images.photoPortfolio4,
        title: 'project title',
        category: 'photography',
        link: '#'
    },
    {
        id: 5,
        Image: images.photoPortfolio5,
        title: 'project title',
        category: 'photography',
        link: '#'
    },
    {
        id: 6,
        Image: images.photoPortfolio6,
        title: 'project title',
        category: 'photography',
        link: '#'
    },
];