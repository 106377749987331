import { images } from '../constants';


const testimonials = [

    {
        id: 1,
        image: images.testimonial1,
        name: 'jason roy',
        title: 'web developer',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
    },
    {
        id: 2,
        image: images.testimonial2,
        name: 'evelyn jones',
        title: 'freelancer',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
    },
    { 
        id: 3,
        image: images.testimonial3,
        name: 'luke wood',
        title: 'graphic designer',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
        
    },
    {
        id: 4,
        image: images.testimonial4,
        name: 'sarah glenn',
        title: 'freelancer',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
    },
    {
        id: 5,
        image: images.testimonial5,
        name: 'samit patel',
        title: 'graphic designer',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum deleniti architecto harum natus aliquid nostrum ad asperiores sequi, eligendi sint assumenda eum illo similique repellendus.',
    }

];

export default testimonials;