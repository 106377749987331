import React from 'react';
import { Home, About, Services, Skills, Resume, Portfolio, Testimonials, Blogs, Contact } from './sections';
import { Header, Footer, ScrollTop } from './components';

const App = () => {
  return (
    <div className="App">
        <Header />
        <Home />
        <About />
        <Skills />
        <Services />
        <Resume />
        <Portfolio />
        <Testimonials />
        <Blogs />
        <Contact />
        <Footer />
        <ScrollTop />
    </div>

  )
}

export default App;
