import React from 'react';
import './PortfolioList.css';
 
const PortfolioList = ({id, title, active, setSelected}) => {
  return (
    <li
        className={active ? "button active" : "button"}
        onClick={() => setSelected(id)}>
        {title}
    </li>
  )
}

export default PortfolioList