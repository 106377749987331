import React, {Component} from 'react';
import axios from "axios";
import './Contact.css';

import { Heading, Button } from '../../components';
import { contactContent } from '../../constants';

const API_PATH = 'https://lorenzo.asdesignsgalaxy.com/api/phpmailer/submit.php';

 
class Contact extends Component{
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      mailSent: false,
      error: null
    }
  }

  // when submit btn is clicked
  handleSubmitForm = (e) => {

    e.preventDefault();

    const submitBtn = document.querySelector('#submit span');
    submitBtn.innerText = 'please wait';

    this.setState({
      mailSent: false
    })

    let formData = new FormData();
    formData.append("name", this.state.name);
    formData.append("email", this.state.email);
    formData.append("subject", this.state.subject);
    formData.append("message", this.state.message);

    axios({
        method: 'post',
        url: `${API_PATH}`,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData
    })
    .then(result => {
        console.log(result)
        this.setState({
          mailSent: result.data.sent,
          name: '', email: '', subject: '', message: ''
        })
        submitBtn.innerText = 'send message';
    })
    .catch(error => this.setState({ error: error.message }));
    console.log(this.state);
  }

  render(){
   return (
     <section className='contact' id='contact'>

      <Heading title={'contact me'} span={'get in touch'} />

      <div className='box-container'> 

        <div className='contact-info'>
          {
          contactContent.map((contact) => {
            let details = contact.content;
            return <div className='info-item' key={contact.id}>
              <div className='icon-item'>
                <div className='icon'>{contact.icon}</div>
              </div>
              <div className='content'>
                <h4>{contact.title}</h4>
                <ol>{details.map(item => {return <p>{item}</p>})}</ol>
              </div>
            </div>
          })
          }
        </div>

        <form method="post" className='contact-form' id="contactUs-Form">
 
          <h3>send message</h3>

          <input type="text" name="name" class="box" id="name" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} placeholder="name" required />
          <input type="email" name="email" class="box" id="email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })}  placeholder="email" required />
          <input type="text" name="subject" class="box" id="subject" value={this.state.subject} onChange={e => this.setState({ subject: e.target.value })} placeholder="subject" />
          <textarea cols="30" rows="10" name="comment" id="comment" class="box" value={this.state.message} onChange={e => this.setState({ message: e.target.value })} placeholder="message"></textarea>
          <button type="submit" onClick={e => this.handleSubmitForm(e)} name="submit" id="submit"><Button title={"send message"} /></button>
          
          {this.state.mailSent &&
              <span className="alert">message sent successfully.</span>
          }
          
        </form>

      </div>

     </section>
   )
 }
}
 
 export default Contact