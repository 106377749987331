import React from 'react';
import './Home.css';

import { images } from '../../constants';
import { Button } from '../../components';

const Home = () => {
  return (

  <section className='home' id='home'>

    <div className='box-container'>

      <div className='content'> 

        <div className='text'>
          <h2>hi there!</h2>
          <h1>I'm lorenzo fellon</h1>
          <h4>I Am passionate <span>web developer</span></h4>
          <p>Creative web developer offering 6+ years of experience providing high-impact web solutions for many different organizations.</p>
          <Button link={'contact'} title={'contact me'} />
        </div>

      </div>

      <div className='image'>
        <img src={images.homeImage} alt={'portfolio-pic'}></img>
      </div>

    </div>

  </section>

  )
}

export default Home