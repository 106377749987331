import React from 'react';
import './Resume.css';

import { Heading } from '../../components';
import { educationContent, experienceContent } from '../../constants';


const Resume = () => { 
  return (
  <section className='resume' id='resume'>

    <Heading title={'Resume'} span={'6+ years of experience'} />

    <div class="box-container">

      <div className='education'>
        {
          educationContent.map((education) => {
            return <div className='box'>
              <div className='content' key={education.id}>
                <h3>{education.title}</h3>
                <span>{education.duration}</span>
                <p>{education.content}</p>
              </div>
            </div>
          })
        }

      </div>

      <div className='experience'>
        {
          experienceContent.map((experience) => {
            return <div className='box'>
              <div className='content' key={experience.id}>
                <h3>{experience.title}</h3>
                <span>{experience.duration}</span>
                <p>{experience.content}</p>
              </div>
            </div>
          })
        }

      </div>

    </div>

  </section>
  )
}

export default Resume;