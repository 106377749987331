import React from 'react'
import './Blogs.css';

import { Heading } from '../../components';
import { blogContent } from '../../constants';


const Blogs = () => {  
  return (
    <section className='blog' id='blog'>

      <Heading title={'my blogs'} span={'latest news'} />
 
      <div className='box-container'>
        {
          blogContent.map((blog) => { 
            return <div className='blog-item' key={blog.id}>
              <div className='image'>
                <span className='date'>{`${blog.date} ${blog.month}, ${blog.year}`}</span>
                <img src={blog.Image} alt='Blog' />
              </div>
            
              <div className='content'>
                <div className='category'>{blog.category}</div><br />
                <a className='main-heading' href={blog.link}>{blog.title}</a>
                <p>{blog.content}</p>    
              </div>

            </div>
          })
        }
      </div>

    </section>
  )
}

export default Blogs