 import React from 'react';
 import './Footer.css';
 import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

 
const Footer = () => {
  return (
    <footer className='footer'>
 
      <div class="footer-item">
        <div class="social">
          <a href="/#"><FaFacebookF className='icon' /></a>
          <a href="/#"><FaTwitter className='icon' /></a>
          <a href="/#"><FaInstagram className='icon' /></a>
          <a href="/#"><FaLinkedin className='icon' /></a>
        </div>
      </div>

      <div className='content'>
        <p>designed by <span>AS_Designs</span> | all rights reserved</p>
      </div>
      
    </footer>
  )
}

export default Footer;