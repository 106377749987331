import React, {useState, useEffect} from 'react';
import Scrollspy from 'react-scrollspy';
import './Header.css';

import { FaBars, FaTimes } from 'react-icons/fa';

 
const Header = () => { 

  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMenuBar = () => setClick(false);

  /* Manage On Scroll Functionality */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll = (e) => {

    /* Sticky Header */
    const header = document.querySelector('.header');
    const scrollTop = window.scrollY;
    scrollTop >= 60 ? header.classList.add('sticky') : header.classList.remove('sticky');

    /* Close Menubar On Scroll */
    const navbar = document.querySelector('.navbar');
    navbar.classList.remove('active');
    setClick(false);

  };

  return (

    <nav className='header'>
 
      <a href='/#' className='logo'>
        <h2> Lorenzo </h2>
      </a>

      <div className='menu-btn' id='menu' onClick={handleClick}>{click ? <FaTimes /> : <FaBars />}</div>

      <Scrollspy className={click ? 'navbar active' : 'navbar'} items={ ['home', 'about', 'services', 'resume', 'portfolio', 'testimonials', 'contact'] } currentClassName="is-current">
        {['home', 'about', 'services', 'resume', 'portfolio', 'testimonials', 'contact'].map((item) => (
          <li key={`link-${item}`}>
            <a href={`#${item}`} onClick={closeMenuBar}>{item}</a>
          </li>
          ))
        } 
      </Scrollspy>

    </nav>
   )
 }
 
 export default Header