const experience = [

    {
        id: 1,
        duration: '2018 - 2019',
        title: 'frontend development',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat est nemo, omnis iste distinctio consequuntur delectus facere suscipit facilis perspiciatis error.',
    },
    {
        id: 2,
        duration: '2019 - 2020',
        title: 'backend development',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat est nemo, omnis iste distinctio consequuntur delectus facere suscipit facilis perspiciatis error.',
    },
    {
        id: 3,
        duration: '2020 - 2021',
        title: 'php development',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat est nemo, omnis iste distinctio consequuntur delectus facere suscipit facilis perspiciatis error.',
    },
 
];

export default experience;