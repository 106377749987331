import React, { useEffect, useState } from 'react';
import './Portfolio.css'; 

import { webPortfolios, designPortfolios, photographyPortfolios } from '../../constants'; 
import { Heading, PortfolioList } from '../../components';

import { BsLink45Deg } from 'react-icons/bs';

const Portfolio = () => {

  const [selected, setSelected] = useState("web");
  const [data, setData] = useState([]);

  const list = [
    {
      id: "web",
      title: "web development",
    },
    {
      id: "design",
      title: "graphic design",
    },
    {
      id: "photography",
      title: "photography",
    },
  ];

  useEffect(() => {
    switch (selected) {
      case "web":
        setData(webPortfolios);
        break;
      case "design":
        setData(designPortfolios);
        break;
      case "photography":
        setData(photographyPortfolios);
        break;
      default:
        setData(webPortfolios);
    }
  }, [selected]);

  return (
  <section className='portfolio' id='portfolio'>

    <Heading title={'my portfolio'} span={'my recent works'} />

    <div className='gallery'>

      <ul class="controls">
        {
          list.map((item) => {
            return <PortfolioList 
            id={item.id} 
            title={item.title}
            active={selected === item.id} 
            setSelected={setSelected} />
          })
        }
      </ul>

      <div className='box-container'>
        {
          data.map((d) => {
            return <div className='portfolio-item'>
              <img src={d.Image} alt='Blog' />
              <div className='content'>
                <h4>{d.title}</h4>
                <p>{d.category}</p>
                <a href={d.link} class="link-btn"><BsLink45Deg /></a>
              </div>

            </div> 
          })
        }
      </div>

    </div>

  </section>
  )
}

export default Portfolio