import React from 'react';
import './Testimonials.css'; 

import { Heading } from '../../components';
import { testiContent } from '../../constants';

import { GoQuote } from 'react-icons/go';

// import Swiper core and required modules
import { Pagination, Autoplay } from 'swiper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination'; 

const Testimonials = () => { 
  return (
    <section className='testimonial' id='testimonials'>

      <Heading title={'testimonials'} span={'what clients say'} />

      <div className='testimonial-slider'>
      <Swiper 
      // install Swiper modules
      modules={[Pagination, Autoplay]}
      spaceBetween={30}
      loop={true}
      slidesPerView={3}
      autoplay={{ delay: 4000, disableOnInteraction: false }}
      pagination={{ clickable: true }}
      breakpoints={{
        0: {
            slidesPerView: 1,
        },

        768: {
            slidesPerView: 2,
        },

        1024: {
            slidesPerView: 3,
        },
      }}
      >
        {
          testiContent.map((testimonial) => {
            return <SwiperSlide className='testi-item' key={testimonial.id}>
              <img src={testimonial.image} alt='testimonial-pic' />

              <div class="intro">
                  <h3>{testimonial.name}</h3>
                  <h5>{testimonial.title}</h5>
              </div>

              <div className='icon'>
                <GoQuote className='quote'/>
              </div>

              <p class="comment">{testimonial.content}</p>

            </SwiperSlide>
          })
        }

      </Swiper>
      </div>
      
    </section>

  )
} 

export default Testimonials