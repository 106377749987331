import React from 'react';
import './Button.css'; 


const Button = ({title, link=''}) => {
  return (
    <a href={`#${link}`} className="btn"><span>{title}</span></a>  
  )
}

export default Button