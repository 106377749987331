import React from 'react';
import CountUp from 'react-countup';
import './About.css';

import { Heading, Button } from '../../components';

const About = () => { 
  return (
    <section className='about' id='about'>

      <Heading title={'about me'} span={'know about me'} />

      <div className='box-container'>

        <div className='content'>

          <h2>My name is lorenzo.</h2>
          <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. 
              Recusandae aut adipisci necessitatibus veniam, provident 
              vero architecto praesentium, non sequi libero corrupti molestias. 
              Architecto voluptate eligendi maiores, error magni accusamus officia.</p>
          <div className='about-info'>
            <h4>age: <span>23</span></h4>
            <h4>gender: <span>male</span></h4>
            <h4>language: <span>urdu, english</span></h4>
            <h4>work: <span>web developer</span></h4>
            <h4>freelance: <span>available</span></h4>
          </div>
          <Button title={'download CV'} />

        </div> 

        <div class="counting">

          <div class="box">
            <CountUp class="count" end={6} duration={3} suffix={'+'} />
            <h3>years of experience</h3>
          </div>
          
          <div class="box">
              <CountUp class="count" end={200} duration={3} suffix={'+'} />
              <h3>projects completed</h3>
          </div>

          <div class="box">
          <CountUp class="count" end={100} duration={3} suffix={'+'} />
              <h3>happy clients</h3>
          </div>
                      
          <div class="box">
          <CountUp class="count" end={25} duration={3} suffix={'+'} />
              <h3>awards won</h3>
          </div>
      
        </div>

      </div>

    </section>
  )
}

export default About;