import homeImage from '../assets/Home/Home-Image.png';

import webPortfolio1 from '../assets/Portfolio/Web-Dev/1.png';
import webPortfolio2 from '../assets/Portfolio/Web-Dev/2.png';
import webPortfolio3 from '../assets/Portfolio/Web-Dev/3.png';
import webPortfolio4 from '../assets/Portfolio/Web-Dev/4.png';
import webPortfolio5 from '../assets/Portfolio/Web-Dev/5.png';
import webPortfolio6 from '../assets/Portfolio/Web-Dev/6.png';

import designPortfolio1 from '../assets/Portfolio/Graphic-Design/1.png';
import designPortfolio2 from '../assets/Portfolio/Graphic-Design/2.png';
import designPortfolio3 from '../assets/Portfolio/Graphic-Design/3.png';
import designPortfolio4 from '../assets/Portfolio/Graphic-Design/4.png';
import designPortfolio5 from '../assets/Portfolio/Graphic-Design/5.png';
import designPortfolio6 from '../assets/Portfolio/Graphic-Design/6.png';

import photoPortfolio1 from '../assets/Portfolio/Photography/1.png';
import photoPortfolio2 from '../assets/Portfolio/Photography/2.png';
import photoPortfolio3 from '../assets/Portfolio/Photography/3.png';
import photoPortfolio4 from '../assets/Portfolio/Photography/4.png';
import photoPortfolio5 from '../assets/Portfolio/Photography/5.png';
import photoPortfolio6 from '../assets/Portfolio/Photography/6.png';

import testimonial1 from '../assets/Testimonials/pic-1.png';
import testimonial2 from '../assets/Testimonials/pic-2.png';
import testimonial3 from '../assets/Testimonials/pic-3.png';
import testimonial4 from '../assets/Testimonials/pic-4.png';
import testimonial5 from '../assets/Testimonials/pic-5.png';


import blog1 from '../assets/Blogs/Blog-1.png';
import blog2 from '../assets/Blogs/Blog-2.png';
import blog3 from '../assets/Blogs/Blog-3.png';



const Images = {
    homeImage,

    webPortfolio1,
    webPortfolio2,
    webPortfolio3,
    webPortfolio4,
    webPortfolio5,
    webPortfolio6,

    designPortfolio1,
    designPortfolio2,
    designPortfolio3,
    designPortfolio4,
    designPortfolio5,
    designPortfolio6,

    photoPortfolio1,
    photoPortfolio2,
    photoPortfolio3,
    photoPortfolio4,
    photoPortfolio5,
    photoPortfolio6,

    testimonial1,
    testimonial2,
    testimonial3,
    testimonial4,
    testimonial5,

    blog1,
    blog2,
    blog3,
};

export default Images;